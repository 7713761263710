import {
  FOG_20,
  ownUpMidtoneTheme,
  OwnUpThemeProvider,
  WHITE_100
} from '@rategravity/own-up-component-library';
import React from 'react';
import { Ankle } from '../components/ankle';
import { HaveQuestions } from '../components/have-questions';
import { Header } from '../components/header';
import { ImageMultiple } from '../components/image-multiple';
import { Layout } from '../components/layout';
import { HowItWorks } from '../components/real-estate/how-it-works';
import {
  agentImages,
  forYouImages,
  RealEstateHeaderImage as HeaderImage
} from '../components/static-images/real-estate';
import {
  agentsImageMultipleValueProps,
  forYouImageMultipleValueProps
} from '../data/content/real-estate';

export const RealEstate = () => (
  <Layout>
    <Header image={<HeaderImage />} headerText="A partner for buyers and their agents" />
    <HowItWorks />
    <OwnUpThemeProvider theme={ownUpMidtoneTheme}>
      <HaveQuestions />
    </OwnUpThemeProvider>
    <ImageMultiple
      headlineText="What's in it for you"
      imageData={forYouImages}
      backgroundColor={WHITE_100}
      valueProps={forYouImageMultipleValueProps}
    />
    <ImageMultiple
      highlightText="WHAT OTHER AGENTS ARE SAYING"
      imageData={agentImages}
      backgroundColor={FOG_20}
      valueProps={agentsImageMultipleValueProps}
    />
    <Ankle />
  </Layout>
);

export default RealEstate;
